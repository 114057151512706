import { useQuery } from 'react-query'

import { getNations } from '@/apis/tea/getNations'

const getQueryKey = () => ['tea/nations']

export default function useNations() {
  return useQuery(
    getQueryKey(),
    async () => {
      const nations = await getNations()
      return nations
    }
  )
}
