import { useMutation } from 'react-query'

import { deleteFestivalSchedule } from '@/apis/festival/deleteFestivalSchedule'

export default function useDeleteFestivalSchedule() {
  return useMutation<void, unknown, number>(
    async (id) => {
      await deleteFestivalSchedule(id)
    })
}
