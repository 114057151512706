import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type CreateTeaOriginRequestDto = {
  name: string;
  description: string;
  nationId: number;
}

const { TEA } = ROUTER

export const postCreateOrigin = async (data: CreateTeaOriginRequestDto) => {
  return request<{ id: number }>({
    url: `${TEA}/origin`,
    method: Methods.POST,
    data
  })
}
