import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type ShoppingFilterModel = {
  id: number;
  code: string;
  title: string;
  subTitle: string;
  word: string;
  image: string;
  show: boolean;
  priority: number;
}

export type GetShoppingFiltersResponseType = ShoppingFilterModel[]

export const getShoppingFilters = () => {
  return request<GetShoppingFiltersResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `${ROUTER.SHOPPING_ADMIN}/filter`,
    method: Methods.GET
  })
}
