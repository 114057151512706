import { Box, Button, Card, CardContent, CardMedia, Modal, Switch, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ProductItemModel } from '@/apis/shopping/getProducts'
import useDeleteFilteredProduct from '@/hooks/shopping/useDeleteFilteredProduct'
import useDeleteProduct from '@/hooks/shopping/useDeleteProduct'
import useFilteredProducts from '@/hooks/shopping/useFilteredProducts'
import usePostCreateFilteredProduct from '@/hooks/shopping/usePostCreateFilteredProduct'
import useProducts from '@/hooks/shopping/useProducts'
import useShoppingFilters from '@/hooks/shopping/useShoppingFilters'

const ProductMainPage = () => {
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({ limit: 5, page: 1 })
  const { data: products, refetch, isLoading } = useProducts(pagination)
  const [allProducts, setAllProducts] = useState<ProductItemModel[]>([])
  const { mutate: deleteProduct } = useDeleteProduct()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentProductIndex, setCurrentProductIndex] = useState(0)
  const { data: filters } = useShoppingFilters()
  const filterIndex = allProducts ? allProducts[currentProductIndex]?.id : 0
  const { data: filteredProducts, isLoading: isLoadingFiltered } = useFilteredProducts(filterIndex)
  const { mutate: deleteFilteredProduct } = useDeleteFilteredProduct()
  const { mutate: createFilteredProduct } = usePostCreateFilteredProduct()

  useEffect(() => {
    if (products) {
      setAllProducts((prevProducts) => [...prevProducts, ...products])
    }
  }, [products])

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 && !isLoading) {
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLoading])

  useEffect(() => {
    refetch()
  }, [pagination, refetch])

  const handleDelete = (id: number) => {
    const confirmDelete = window.confirm('삭제하겠습니까?')
    if (confirmDelete) {
      deleteProduct(id, {
        onSuccess: () => {
          setAllProducts((prevProducts) => prevProducts.filter((product) => product.id !== id))
        }
      })
    }
  }

  const handleOpenModal = (index: number) => {
    setCurrentProductIndex(index)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => setIsModalOpen(false)

  const handleNextProduct = () => {
    if (allProducts) {
      setCurrentProductIndex((prevIndex) => prevIndex === allProducts.length - 1 ? 0 : prevIndex + 1)
    }
  }

  const handlePrevProduct = () => {
    if (allProducts) {
      setCurrentProductIndex((prevIndex) => prevIndex === 0 ? allProducts.length - 1 : prevIndex - 1)
    }
  }

  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Typography variant="h4">Products</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {allProducts?.map((product, index) => (
          <Card
            key={product.id}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              minWidth: 275,
              cursor: 'pointer',
              boxShadow: 3,
              transition: 'transform 0.2s',
              '&:hover': { transform: 'scale(1.02)' }
            }}
          >
            {product.images[0] && (
              <CardMedia
                component="img"
                image={product.images[0]}
                alt={product.name}
                sx={{ width: { xs: '100%', sm: 160 }, height: 160 }}
              />
            )}
            <CardContent sx={{ flex: '1 1 auto' }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold' }}>{product.name}</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginBottom: 1 }}>
                {product.summary}
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{ fontWeight: 'medium' }}>
                {product.price.toFixed(2)}￦
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: 1 }}>
                Owner: {product.shop.owner.nickname} | Store: {product.shop.name}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => navigate(`${product.id}`)}
                >
                  View Details
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleOpenModal(index)}
                >
                  필터 설정
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(product.id)}
                >
                  Delete
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          {allProducts && allProducts[currentProductIndex] && (
            <div>
              <h2>{allProducts[currentProductIndex].name}</h2>
              <p>{allProducts[currentProductIndex].summary}</p>
              <div>
                {filters && filters.map((filter) => {
                  if (!isLoadingFiltered) {
                    const exists = filteredProducts?.some((fp) => fp.code === filter.code)
                    return (
                      <div key={filter.id}>
                        <Switch
                          checked={exists}
                          onChange={async () => {
                            if (exists && filteredProducts) {
                              const filteredProductToDelete = filteredProducts.find((fp) => fp.code === filter.code)
                              if (filteredProductToDelete) {
                                await deleteFilteredProduct({ id: filteredProductToDelete.id })
                              }
                            } else {
                              await createFilteredProduct({
                                productId: allProducts[currentProductIndex].id,
                                filterId: filter.id
                              })
                            }
                          }}
                        />
                        {filter.title}
                      </div>
                    )
                  }
                })}
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button onClick={handlePrevProduct}>이전</Button>
                <Button onClick={handleNextProduct}>다음</Button>
              </Box>
            </div>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleCloseModal}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflow: 'auto'
}

export default ProductMainPage
