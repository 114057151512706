import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type UpdateFestivalScheduleRequestDto = Partial<{
  title: string;
  description: string;
  festivalLocation: string;
  startTime: Date;
  endTime: Date;
  image: string;
  webUrl: string;
}>

const { FESTIVAL_ADMIN } = ROUTER

export const patchFestivalSchedule = async(id: number, data: UpdateFestivalScheduleRequestDto) => {
  return request<void>({
    url: `${FESTIVAL_ADMIN}/schedule/${id}`,
    method: Methods.PATCH,
    data: data
  })
}
