import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { FESTIVAL_ADMIN } = ROUTER

export type CreateFestivalScheduleRequestDto = {
  title: string;
  description: string;
  festivalLocation: string;
  startTime: Date;
  endTime: Date;
  image?: string;
  webUrl?: string;
}

export const postCreateFestivalSchedule = async(createFestivalScheduleDto: CreateFestivalScheduleRequestDto) => {
  return request<void>({
    url: `${FESTIVAL_ADMIN}/schedule`,
    method: Methods.POST,
    data: createFestivalScheduleDto
  })
}
