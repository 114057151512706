import { useMutation } from 'react-query'

import { CreateTeaOriginRequestDto, postCreateOrigin } from '@/apis/tea/postCreateOrigin'

export default function usePostCreateOrigin() {
  return useMutation<{ id: number }, unknown, CreateTeaOriginRequestDto>(
    async (data) => {
      return await postCreateOrigin(data)
    }
  )
}
