import styled from '@emotion/styled'
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useParams } from 'react-router-dom'

import useTea from '@/hooks/tea/useTea'

const TeaDetailPage = () => {
  const { id } = useParams()
  const { data: tea, isLoading } = useTea(Number(id))

  if (isLoading || !tea) return <CircularProgress />

  return (
    <PageWrapper>
      <h1>차 상세</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                sx={{ width: '200px', backgroundColor: '#f5f5f5' }}>
                이름
              </TableCell>
              <TableCell>{tea.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                sx={{ backgroundColor: '#f5f5f5' }}>
                설명
              </TableCell>
              <TableCell>{tea.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                sx={{ backgroundColor: '#f5f5f5' }}>
                원산지
              </TableCell>
              <TableCell>
                {tea.origin
                  ? `${tea.origin.nation?.name || ''} ${tea.origin.name ? `- ${tea.origin.name}` : ''}`
                  : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                sx={{ backgroundColor: '#f5f5f5' }}>
                브랜드
              </TableCell>
              <TableCell>{tea.brand?.name || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                sx={{ backgroundColor: '#f5f5f5' }}>
                부모 차
              </TableCell>
              <TableCell>
                {tea.parentTeas && tea.parentTeas.length > 0 ? (
                  <ul>
                    {tea.parentTeas.map((parentTea) => (
                      <li key={parentTea.id}>{parentTea.name}</li>
                    ))}
                  </ul>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                sx={{ backgroundColor: '#f5f5f5' }}>
                자식 차
              </TableCell>
              <TableCell>
                {tea.childTeas && tea.childTeas.length > 0 ? (
                  <ul>
                    {tea.childTeas.map((childTea) => (
                      <li key={childTea.id}>{childTea.name}</li>
                    ))}
                  </ul>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                sx={{ backgroundColor: '#f5f5f5' }}>
                우선순위
              </TableCell>
              <TableCell>{tea.priority}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PageWrapper>
  )
}

export default TeaDetailPage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
