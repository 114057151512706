
const NotificationMainPage = () => {
  return (
    <div style={{ padding: '10px 20px' }}>
      <h1>카카오톡 커스텀 알림</h1>
      <h2>찻집 사장님 대상</h2>
      <h1>앱 푸시 알림</h1>
      <h2>찻집 사장님 대상</h2>
    </div>
  )
}

export default NotificationMainPage
