import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { TEA } = ROUTER

export const deleteNation = async (id: number) => {
  return request<void>({
    url: `${TEA}/nation/${id}`,
    method: Methods.DELETE
  })
}
