import { Box, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import usePostCreateFestivalSchedule from '@/hooks/festival/usePostCreateFestivalSchedule'

const FestivalScheduleCreatePage = () => {
  const navigate = useNavigate()
  const { mutate: createSchedule } = usePostCreateFestivalSchedule()

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    festivalLocation: '',
    startTime: '',
    endTime: '',
    image: '',
    webUrl: ''
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    createSchedule(
      {
        ...formData,
        startTime: new Date(formData.startTime),
        endTime: new Date(formData.endTime)
      },
      {
        onSuccess: () => {
          navigate('/festival/schedule', { replace: true })
        }
      }
    )
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography
        variant="h4"
        gutterBottom>
        축제 일정 생성
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '600px' }}>
        <TextField
          label="일정 제목"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="상세 설명"
          name="description"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          required
        />
        <TextField
          label="장소"
          name="festivalLocation"
          value={formData.festivalLocation}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="시작 시간"
          name="startTime"
          type="datetime-local"
          value={formData.startTime}
          onChange={handleChange}
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="종료 시간"
          name="endTime"
          type="datetime-local"
          value={formData.endTime}
          onChange={handleChange}
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="이미지 URL"
          name="image"
          value={formData.image}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="웹사이트 URL"
          name="webUrl"
          value={formData.webUrl}
          onChange={handleChange}
          fullWidth
        />
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/festival/schedule')}
          >
            취소
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{ backgroundColor: '#01c897', color: 'white' }}
          >
            생성
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FestivalScheduleCreatePage
