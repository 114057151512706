import { useMutation } from 'react-query'

import { patchUpdateTea, UpdateTeaRequestDto } from '@/apis/tea/patchUpdateTea'

type UpdateTeaParams = {
  id: number;
  data: UpdateTeaRequestDto;
}

export default function usePatchUpdateTea() {
  return useMutation<void, unknown, UpdateTeaParams>(
    async ({ id, data }) => {
      await patchUpdateTea(id, data)
    }
  )
}
