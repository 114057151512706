import { useMutation } from 'react-query'

import { patchUpdateOrigin, UpdateTeaOriginRequestDto } from '@/apis/tea/patchUpdateOrigin'

type UpdateOriginParams = {
  id: number;
  data: UpdateTeaOriginRequestDto;
}

export default function usePatchUpdateOrigin() {
  return useMutation<void, unknown, UpdateOriginParams>(
    async ({ id, data }) => {
      await patchUpdateOrigin(id, data)
    }
  )
}
