import styled from '@emotion/styled'
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import useTeas from '@/hooks/tea/useTeas'

const TeaMainPage = () => {
  const navigate = useNavigate()
  const { data: teas, isLoading } = useTeas()

  return (
    <PageWrapper>
      <HeaderWrapper>
        <h1>차 관리</h1>
        <ButtonWrapper>
          <Button
            variant="contained"
            onClick={() => navigate('tea/create')}>
            차 추가
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('nation')}>
            차 생산국 관리
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('origin')}>
            차 산지 관리
          </Button>
        </ButtonWrapper>
      </HeaderWrapper>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>이름</TableCell>
                <TableCell>설명</TableCell>
                <TableCell>원산지</TableCell>
                <TableCell>브랜드</TableCell>
                <TableCell>관리</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teas?.map((tea) => (
                <TableRow key={tea.id}>
                  <TableCell>{tea.id}</TableCell>
                  <TableCell>
                    <Link to={`tea/${tea.id}`}>{tea.name}</Link>
                  </TableCell>
                  <TableCell>{tea.description}</TableCell>
                  <TableCell>{tea.origin?.name}</TableCell>
                  <TableCell>{tea.brand?.name}</TableCell>
                  <TableCell>
                    <Button onClick={() => navigate(`tea/update/${tea.id}`)}>
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </PageWrapper>
  )
}

export default TeaMainPage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
