import { useMutation } from 'react-query'

import { CreateNationRequestDto, postCreateNation } from '@/apis/tea/postCreateNation'

export default function usePostCreateNation() {
  return useMutation<{ id: number }, unknown, CreateNationRequestDto>(
    async (data) => {
      return await postCreateNation(data)
    }
  )
}
