import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { FestivalScheduleModel } from './getFestivalSchedules'

const { FESTIVAL_ADMIN } = ROUTER

export const getFestivalScheduleById = (id: number) => {
  return request<FestivalScheduleModel>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `${FESTIVAL_ADMIN}/schedule/${id}`,
    method: Methods.GET
  })
}
