import { useMutation } from 'react-query'

import { patchTeahouseFilter, PatchTeahouseFilterType } from '@/apis/teahouse/patchFilter'

export default function usePatchTeahouseFilter() {
  return useMutation<void, unknown, PatchTeahouseFilterType>(
    async ({
      id,
      code,
      title,
      subTitle,
      word,
      image,
      show,
      priority
    }) => {
      await patchTeahouseFilter({
        id,
        code,
        title,
        subTitle,
        word,
        image,
        show,
        priority
      })
    })
}
