import styled from '@emotion/styled'
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useDeleteNation from '@/hooks/tea/useDeleteNation'
import useNations from '@/hooks/tea/useNations'

const TeaNationMainPage = () => {
  const navigate = useNavigate()
  const { data: nations, isLoading, refetch } = useNations()
  const { mutate: deleteNation } = useDeleteNation()

  const handleDelete = async (id: number) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      await deleteNation(id, {
        onSuccess: () => {
          refetch()
        }
      })
    }
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        <h1>차 생산국 관리</h1>
        <Button
          variant="contained"
          onClick={() => navigate('create')}>
          생산국 추가
        </Button>
      </HeaderWrapper>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>국가명</TableCell>
                <TableCell>코드</TableCell>
                <TableCell>관리</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nations?.map((nation) => (
                <TableRow key={nation.id}>
                  <TableCell>{nation.id}</TableCell>
                  <TableCell>{nation.name}</TableCell>
                  <TableCell>{nation.code}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => navigate(`update/${nation.id}`)}>
                      수정
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleDelete(nation.id)}>
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </PageWrapper>
  )
}

export default TeaNationMainPage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
