import { useMutation } from 'react-query'

import { postCreateShoppingFilter, PostCreateShoppingFilterType } from '@/apis/shopping/postCreateShoppingFilter'

export default function usePostCreateShoppingFilter() {
  return useMutation<{ id: number }, unknown, PostCreateShoppingFilterType>(
    async (data) => {
      return await postCreateShoppingFilter(data)
    }
  )
}
