import { useMutation } from 'react-query'

import { deleteNation } from '@/apis/tea/deleteNation'

export default function useDeleteNation() {
  return useMutation<void, unknown, number>(
    async (id) => {
      await deleteNation(id)
    }
  )
}
