import { Box, Button, Card, CardContent, CircularProgress, Modal, Switch, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useDeleteFilteredProduct from '@/hooks/shopping/useDeleteFilteredProduct'
import useFilteredProducts from '@/hooks/shopping/useFilteredProducts'
import usePostCreateFilteredProduct from '@/hooks/shopping/usePostCreateFilteredProduct'
import useProducts from '@/hooks/shopping/useProducts'
import useShoppingFilters from '@/hooks/shopping/useShoppingFilters'
import useShops from '@/hooks/shopping/useShops'
import timeSince from '@/libs/timeSince'

const ShoppingMainPage = () => {
  const navigate = useNavigate()
  const { data: shops } = useShops()
  const { data: products, isLoading } = useProducts({ page: 1, limit: 100 })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentProductIndex, setCurrentProductIndex] = useState(0)
  const { data: filters } = useShoppingFilters()
  const sortedProducts = products?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
  const filterIndex = products ? products[currentProductIndex]?.id : 0
  const { data: filteredProducts, isLoading: isLoadingFiltered } = useFilteredProducts(filterIndex)
  const { mutate: deleteFilteredProduct } = useDeleteFilteredProduct()
  const { mutate: createFilteredProduct } = usePostCreateFilteredProduct()

  const handleOpenModal = (index: number) => {
    setCurrentProductIndex(index)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => setIsModalOpen(false)

  const handleNextProduct = () => {
    if (sortedProducts) {
      setCurrentProductIndex((prevIndex) => prevIndex === sortedProducts.length - 1 ? 0 : prevIndex + 1
      )
    }
  }

  const handlePrevProduct = () => {
    if (sortedProducts) {
      setCurrentProductIndex((prevIndex) => prevIndex === 0 ? sortedProducts.length - 1 : prevIndex - 1
      )
    }
  }

  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Typography variant="h4">Shopping</Typography>

      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('shop/create')}
        >
          상점 만들기
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('filter')}
        >
          필터
        </Button>
        <Button
          style={{ color: 'white', backgroundColor: '#01c897' }}
          onClick={() => handleOpenModal(0)}
        >
          상품 - 필터
        </Button>
      </Box>

      <Typography variant="h5">Shops</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {shops?.map((shop) => (
          <Card
            onClick={() => navigate(`shop/${shop.id}`)}
            key={shop.id}
            sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h6">{shop.name}</Typography>
              <Typography color="textSecondary">
                Owner: {shop.owner.nickname} ({shop.owner.handle})
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          <h2>상품 필터 설정</h2>
          {isLoading ? (
            <CircularProgress />
          ) : sortedProducts && sortedProducts[currentProductIndex] && (
            <div>
              <p><strong>ID:</strong> {sortedProducts[currentProductIndex].id}</p>
              <p><strong>생성일:</strong> {timeSince(sortedProducts[currentProductIndex].createdAt)}</p>
              <p><strong>{sortedProducts[currentProductIndex].name}</strong></p>
              <p>{sortedProducts[currentProductIndex].summary}</p>
              <div>
                {filters && filters.map((filter) => {
                  if (!isLoadingFiltered) {
                    const exists = filteredProducts?.some((fp) => fp.code === filter.code)
                    return (
                      <div key={filter.id}>
                        <Switch
                          checked={exists}
                          onChange={async () => {
                            if (exists && filteredProducts) {
                              const filteredProductToDelete = filteredProducts.find((fp) => fp.code === filter.code)
                              if (filteredProductToDelete) {
                                await deleteFilteredProduct({ id: filteredProductToDelete.id })
                              }
                            } else {
                              await createFilteredProduct({
                                productId: sortedProducts[currentProductIndex].id,
                                filterId: filter.id
                              })
                            }
                          }}
                        />
                        {filter.title}
                      </div>
                    )
                  }
                })}
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button onClick={handlePrevProduct}>이전</Button>
                <Button onClick={handleNextProduct}>다음</Button>
              </Box>
            </div>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleCloseModal}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflow: 'auto'
}

export default ShoppingMainPage
