import { useMutation } from 'react-query'

import { deleteOrigin } from '@/apis/tea/deleteOrigin'

export default function useDeleteOrigin() {
  return useMutation<void, unknown, number>(
    async (id) => {
      await deleteOrigin(id)
    }
  )
}
