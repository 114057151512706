import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type PostCreateFilteredProductType = {
  filterId: number;
  productId: number;
}

const { SHOPPING_ADMIN } = ROUTER

export const postCreateFilteredProduct = async({ productId, filterId }: PostCreateFilteredProductType) => {
  return request<{ id: number }>({
    url: `${SHOPPING_ADMIN}/filtered-product/product/${productId}/filter/${filterId}`,
    method: Methods.POST
  })
}
