import { useMutation } from 'react-query'

import { patchFestivalSchedule, UpdateFestivalScheduleRequestDto } from '@/apis/festival/patchFestivalSchedule'

export default function useUpdateFestivalSchedule(id: number) {
  return useMutation<void, unknown, UpdateFestivalScheduleRequestDto>(
    async (data) => {
      await patchFestivalSchedule(id, data)
    })
}
