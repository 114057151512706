import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type CreateNationRequestDto = {
  name: string;
  code: string;
}

const { TEA } = ROUTER

export const postCreateNation = async (data: CreateNationRequestDto) => {
  return request<{ id: number }>({
    url: `${TEA}/nation`,
    method: Methods.POST,
    data
  })
}
