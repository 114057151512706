import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { Tea } from './getTeas'

const { TEA } = ROUTER

export const getTea = (id: number) => {
  return request<Tea>({
    url: `${TEA}/tea/${id}`,
    method: Methods.GET
  })
}
