import { useQuery } from 'react-query'

import { getOrigin } from '@/apis/tea/getOrigin'

export default function useOrigin(id: number) {
  return useQuery(
    ['origin', id],
    async () => {
      const origin = await getOrigin(id)
      return origin
    },
    { enabled: !!id }
  )
}
