import { useMutation } from 'react-query'

import { postCreateFilteredProduct, PostCreateFilteredProductType } from '@/apis/shopping/postCreateFilteredProduct'

export default function usePostCreateFilteredProduct() {
  return useMutation<{ id: number }, unknown, PostCreateFilteredProductType>(
    async (data) => {
      return await postCreateFilteredProduct(data)
    }
  )
}
