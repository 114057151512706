import { useQuery } from 'react-query'

import { getNation } from '@/apis/tea/getNation'

export default function useNation(id: number) {
  return useQuery(
    ['nation', id],
    async () => {
      const nation = await getNation(id)
      return nation
    },
    { enabled: !!id }
  )
}
