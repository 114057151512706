import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type PostCreatePostFilterType = {
  code: string;
  title: string;
  subTitle: string;
  word: string;
  image: string;
  show: boolean;
  priority: number;
}

const { POST } = ROUTER

export const postCreatePostFilter = async(data: PostCreatePostFilterType) => {
  return request<{ id: number }>({
    url: `${POST}/admin/filter`,
    method: Methods.POST,
    data
  })
}
