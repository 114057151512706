import { Button, CircularProgress, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TeahouseFilterModel } from '@/apis/teahouse/getTeahouseFilters'
import useDeleteTeahouseFilter from '@/hooks/teahouse/useDeleteTeahouseFilter'
import usePatchTeahouseFilter from '@/hooks/teahouse/usePatchTeahouseFilter'
import useTeahouseFilters from '@/hooks/teahouse/useTeahouseFilters'

const TeahouseFilterPage = () => {
  const { data: filters, isLoading, refetch } = useTeahouseFilters()
  const navigate = useNavigate()

  return (
    <div>
      <h1>찻집 필터</h1>
      <Button onClick={() => navigate('create')}>생성</Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>SubTitle</TableCell>
            <TableCell>Word</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Show</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={9}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            filters?.map((filter) => (
              <TeahouseFilter
                key={filter.id}
                refresh={() => refetch()}
                filter={filter}
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
}

type TeahouseFilterProps = {
  filter: TeahouseFilterModel;
  refresh: () => void;
}

const TeahouseFilter = ({ filter, refresh }: TeahouseFilterProps) => {
  const { mutate: deleteFilter } = useDeleteTeahouseFilter()
  const { mutate: patchFilter } = usePatchTeahouseFilter()
  const [show, setShow] = useState(filter.show)

  const handleDelete = async () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      await deleteFilter({ id: filter.id }, {
        onSuccess: () => {
          refresh()
        }
      })
    }
  }

  const handleSwitchChange = async () => {
    try {
      await patchFilter({ ...filter, show: !show })
      setShow(!show)
      refresh()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <TableRow>
      <TableCell>{filter.id}</TableCell>
      <TableCell>{filter.code}</TableCell>
      <TableCell>{filter.title}</TableCell>
      <TableCell>{filter.subTitle}</TableCell>
      <TableCell>{filter.word}</TableCell>
      <TableCell>{filter.image}</TableCell>
      <TableCell>
        <Switch
          checked={show}
          onChange={handleSwitchChange}
        />
      </TableCell>
      <TableCell>{filter.priority}</TableCell>
      <TableCell>
        <Button onClick={handleDelete}>삭제</Button>
      </TableCell>
    </TableRow>
  )
}

export default TeahouseFilterPage
