import { Box, Button, Switch, TextField } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PostCreateShoppingFilterType } from '@/apis/shopping/postCreateShoppingFilter'
import usePostCreateShoppingFilter from '@/hooks/shopping/usePostCreateShoppingFilter'

const ShoppingFilterCreatePage = () => {
  const navigate = useNavigate()
  const { mutate } = usePostCreateShoppingFilter()
  const [filterData, setFilterData] = useState<PostCreateShoppingFilterType>({
    code: '',
    title: '',
    subTitle: '',
    word: '',
    image: '',
    show: false,
    priority: 1
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 3 }}>
      <h1>필터 만들기</h1>

      <TextField
        label="코드(category)"
        fullWidth
        value={filterData.code}
        onChange={(e) => setFilterData({ ...filterData, code: e.target.value })}
      />

      <TextField
        label="제목(title)"
        fullWidth
        value={filterData.title}
        onChange={(e) => setFilterData({ ...filterData, title: e.target.value })}
      />

      <TextField
        label="부제목(subTitle)"
        fullWidth
        value={filterData.subTitle}
        onChange={(e) => setFilterData({ ...filterData, subTitle: e.target.value })}
      />

      <TextField
        label="단어(word)"
        fullWidth
        value={filterData.word}
        onChange={(e) => setFilterData({ ...filterData, word: e.target.value })}
      />

      <TextField
        label="이미지 URL(image)"
        fullWidth
        value={filterData.image}
        onChange={(e) => setFilterData({ ...filterData, image: e.target.value })}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>보이기</span>
        <Switch
          checked={filterData.show}
          onChange={(e) => setFilterData({ ...filterData, show: e.target.checked })}
        />
      </Box>

      <TextField
        label="우선순위(priority)"
        type="number"
        fullWidth
        value={filterData.priority}
        onChange={(e) => setFilterData({ ...filterData, priority: Number(e.target.value) })}
      />

      <Button
        variant="contained"
        onClick={async() => {
          await mutate(filterData)
          navigate(-1)
        }}
      >
        만들기
      </Button>
    </Box>
  )
}

export default ShoppingFilterCreatePage
