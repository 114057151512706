import styled from '@emotion/styled'
import { format } from 'date-fns'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { type UserActivityLog, getDailyStats, getUserActivityLogs } from '@/apis/log/getUserActivityLogs'

const DAU_GOAL = 50

const ActivityLogPage = () => {
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0])

  const { data: logs = [] } = useQuery({
    queryKey: ['activity-logs'],
    queryFn: getUserActivityLogs
  })

  const { data: dailyStats = [] } = useQuery({
    queryKey: ['daily-stats'],
    queryFn: () => getDailyStats(7)
  })

  // Group logs by ip_address and user_id combination and filter by selected date
  const groupedLogs = logs.reduce<Record<string, UserActivityLog[]>>((acc, log) => {
    const logDate = new Date(log.created_at).toISOString().split('T')[0]
    if (logDate === selectedDate) {
      const key = `${log.ip_address}-${log.user_id}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(log)
    }
    return acc
  }, {})

  return (
    <Container>
      <Header>
        <h1>Activity Logs</h1>
        <StatsContainer>
          <h2>Daily Statistics (Last 7 Days)</h2>
          <StatsGrid>
            {dailyStats.map((stat) => (
              <StatCard
                key={stat.date}
                onClick={() => setSelectedDate(stat.date)}
                isSelected={selectedDate === stat.date}
              >
                <StatDate>{format(new Date(stat.date), 'MMM dd')}</StatDate>
                <StatValue>
                  <strong>{stat.unique_users}</strong>
                  <span>/ {DAU_GOAL} Users</span>
                </StatValue>
                <ProgressBar>
                  <Progress width={Math.min((stat.unique_users / DAU_GOAL) * 100, 100)} />
                </ProgressBar>
                <StatDetail>
                  <div>Total Activities: {stat.total_activities}</div>
                  <div>Avg. Activities per User: {(stat.total_activities / stat.unique_users).toFixed(1)}</div>
                </StatDetail>
              </StatCard>
            ))}
          </StatsGrid>
        </StatsContainer>
      </Header>

      <ActivitySection>
        <h2>User Activities - {format(new Date(selectedDate), 'yyyy년 MM월 dd일')}</h2>
        {Object.entries(groupedLogs).length === 0 ? (
          <EmptyState>선택한 날짜에 활동 기록이 없습니다.</EmptyState>
        ) : (
          Object.entries(groupedLogs).map(([key, userLogs]) => (
            <UserTimelineSection key={key}>
              <UserInfo>
                <h3>User Information</h3>
                <p>IP: {userLogs[0].ip_address}</p>
                <p>User ID: {userLogs[0].user_id}</p>
                <p>Device: {userLogs[0].device_info}</p>
              </UserInfo>
              <Timeline>
                {userLogs.map((log, index) => (
                  <TimelineItem key={log.id}>
                    <TimelineContent>
                      <Time>{format(new Date(log.created_at), 'HH:mm:ss')}</Time>
                      <Activity>
                        <Method>{log.method}</Method>
                        <Action>{log.action}</Action>
                      </Activity>
                    </TimelineContent>
                    {index < userLogs.length - 1 && <Arrow>↓</Arrow>}
                  </TimelineItem>
                ))}
              </Timeline>
            </UserTimelineSection>
          ))
        )}
      </ActivitySection>
    </Container>
  )
}

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`

const Header = styled.div`
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 2rem;
  }
`

const StatsContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
`

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`

interface StatCardProps {
  isSelected: boolean;
}

const StatCard = styled.div<StatCardProps>`
  background: ${(props) => props.isSelected ? '#e3f2fd' : '#f8f9fa'};
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid ${(props) => props.isSelected ? '#007bff' : 'transparent'};

  &:hover {
    background: ${(props) => props.isSelected ? '#e3f2fd' : '#f1f3f5'};
    transform: translateY(-2px);
  }
`

const StatDate = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`

const StatValue = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  strong {
    font-size: 1.5rem;
  }

  span {
    color: #666;
  }
`

const ProgressBar = styled.div`
  height: 6px;
  background: #e9ecef;
  border-radius: 3px;
  margin-bottom: 1rem;
`

interface ProgressProps {
  width: number;
}

const Progress = styled.div<ProgressProps>`
  height: 100%;
  width: ${(props) => props.width}%;
  background: ${(props) => props.width >= 100 ? '#28a745' : '#007bff'};
  border-radius: 3px;
  transition: width 0.3s ease;
`

const StatDetail = styled.div`
  font-size: 0.9rem;
  color: #666;

  div {
    margin-bottom: 0.25rem;
  }
`

const ActivitySection = styled.div`
  h2 {
    margin-bottom: 1.5rem;
  }
`

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  color: #666;
  font-size: 1.1rem;
`

const UserTimelineSection = styled.section`
  margin-bottom: 3rem;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
`

const UserInfo = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0.25rem 0;
    color: #666;
  }
`

const Timeline = styled.div`
  padding-left: 2rem;
`

const TimelineItem = styled.div`
  position: relative;
  padding-bottom: 1.5rem;
`

const TimelineContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const Time = styled.span`
  min-width: 180px;
  color: #666;
  font-size: 0.9rem;
`

const Activity = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Method = styled.span`
  padding: 0.25rem 0.5rem;
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
`

const Action = styled.span`
  color: #333;
`

const Arrow = styled.div`
  position: absolute;
  left: 90px;
  bottom: 0;
  color: #999;
  font-size: 1.2rem;
`

export default ActivityLogPage
