import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type Tea = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string;
  description: string;
  approved: boolean;
  priority: number;
  origin?: {
    id: number;
    name: string;
    nation?: {
      id: number;
      name: string;
    };
  } | null;
  brand?: {
    id: number;
    name: string;
  } | null;
  imageAndTeas: any[];
  parentTeas: Tea[];
  childTeas: Tea[];
}

const { TEA } = ROUTER

export const getTeas = () => {
  return request<Tea[]>({
    url: `${TEA}/tea`,
    method: Methods.GET
  })
}
