import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type PatchShoppingFilterType = {
  id: number;
  code: string;
  title: string;
  subTitle: string;
  word: string;
  image: string;
  show: boolean;
  priority: number;
}

const { SHOPPING_ADMIN } = ROUTER

export const patchShoppingFilter = async({ id, code, title, subTitle, word, image, show, priority }: PatchShoppingFilterType) => {
  return request<{ id: number }>({
    url: `${SHOPPING_ADMIN}/filter/${id}`,
    method: Methods.PATCH,
    data: { code, title, subTitle, word, image, show, priority }
  })
}
