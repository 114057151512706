import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type TeaOrigin = {
  id: number;
  name: string;
  description: string;
  nationId: number;
}

const { TEA } = ROUTER

export const getOrigins = () => {
  return request<TeaOrigin[]>({
    url: `${TEA}/origin`,
    method: Methods.GET
  })
}
