import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type FilteredProductModel = {
  id: number;
  code: string;
  productId: number;
}

export type GetFilteredProductsResponseType = FilteredProductModel[]

export const getFilteredProducts = (productId: number) => {
  return request<GetFilteredProductsResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `${ROUTER.SHOPPING_ADMIN}/filtered-product/product/${productId}`,
    method: Methods.GET
  })
}
