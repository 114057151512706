import { useMutation } from 'react-query'

import { CreateFestivalScheduleRequestDto, postCreateFestivalSchedule } from '@/apis/festival/postCreateFestivalSchedule'

export default function usePostCreateFestivalSchedule() {
  return useMutation<void, unknown, CreateFestivalScheduleRequestDto>(
    async (data) => {
      await postCreateFestivalSchedule(data)
    })
}
