import styled from '@emotion/styled'
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useDeleteOrigin from '@/hooks/tea/useDeleteOrigin'
import useNations from '@/hooks/tea/useNations'
import useOrigins from '@/hooks/tea/useOrigins'

const TeaOriginMainPage = () => {
  const navigate = useNavigate()
  const { data: origins, isLoading, refetch } = useOrigins()
  const { data: nations } = useNations()
  const { mutate: deleteOrigin } = useDeleteOrigin()

  const handleDelete = async (id: number) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      await deleteOrigin(id, {
        onSuccess: () => {
          refetch()
        }
      })
    }
  }

  const getNationName = (nationId: number) => {
    return nations?.find((n) => n.id === nationId)?.name || '-'
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        <h1>차 산지 관리</h1>
        <Button
          variant="contained"
          onClick={() => navigate('create')}>
          산지 추가
        </Button>
      </HeaderWrapper>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>산지명</TableCell>
                <TableCell>설명</TableCell>
                <TableCell>생산국</TableCell>
                <TableCell>관리</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {origins?.map((origin) => (
                <TableRow key={origin.id}>
                  <TableCell>{origin.id}</TableCell>
                  <TableCell>{origin.name}</TableCell>
                  <TableCell>{origin.description}</TableCell>
                  <TableCell>{getNationName(origin.nationId)}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => navigate(`update/${origin.id}`)}>
                      수정
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleDelete(origin.id)}>
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </PageWrapper>
  )
}

export default TeaOriginMainPage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
