import styled from '@emotion/styled'
import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import usePostCreateNation from '@/hooks/tea/usePostCreateNation'

const TeaNationCreatePage = () => {
  const navigate = useNavigate()
  const { mutate: createNation } = usePostCreateNation()
  const [nationData, setNationData] = useState({
    name: '',
    code: ''
  })

  const handleSubmit = async () => {
    if (!nationData.name || !nationData.code) {
      alert('모든 필드를 입력해주세요.')
      return
    }

    createNation(nationData, {
      onSuccess: () => {
        alert('생산국이 생성되었습니다.')
        navigate(-1)
      },
      onError: () => {
        alert('생산국 생성에 실패했습니다.')
      }
    })
  }

  return (
    <PageWrapper>
      <h1>생산국 추가</h1>
      <FormWrapper>
        <TextField
          label="국가명"
          value={nationData.name}
          onChange={(e) => setNationData({ ...nationData, name: e.target.value })}
        />
        <TextField
          label="국가 코드"
          value={nationData.code}
          onChange={(e) => setNationData({ ...nationData, code: e.target.value })}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}>
          생성
        </Button>
      </FormWrapper>
    </PageWrapper>
  )
}

export default TeaNationCreatePage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`
