import { useQuery } from 'react-query'

import { getOrigins } from '@/apis/tea/getOrigins'

const getQueryKey = () => ['tea/origins']

export default function useOrigins() {
  return useQuery(
    getQueryKey(),
    async () => {
      const origins = await getOrigins()
      return origins
    }
  )
}
