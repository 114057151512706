import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type CreateTeaRequestDto = {
  name: string;
  description: string;
  imageIds: number[];
  originId?: number | null;
  brandId?: number | null;
  parentTeaIds?: number[];
  childTeaIds?: number[];
  priority?: number;
}

const { TEA } = ROUTER

export const postCreateTea = async (data: CreateTeaRequestDto) => {
  return request<{ id: number }>({
    url: `${TEA}/tea`,
    method: Methods.POST,
    data
  })
}
