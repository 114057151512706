import { useQuery } from 'react-query'

import { getFestivalSchedules } from '@/apis/festival/getFestivalSchedules'

const getQueryKey = () => ['festival-schedules']

export default function useFestivalSchedules() {
  return useQuery(
    getQueryKey(),
    async () => {
      const schedules = await getFestivalSchedules()
      return schedules
    }
  )
}
