import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { TeaOrigin } from './getOrigins'

const { TEA } = ROUTER

export const getOrigin = (id: number) => {
  return request<TeaOrigin>({
    url: `${TEA}/origin/${id}`,
    method: Methods.GET
  })
}
