import { useMutation } from 'react-query'

import { patchPostFilter, PatchPostFilterType } from '@/apis/post/patchPostFilter'

export default function usePatchPostFilter() {
  return useMutation<void, unknown, PatchPostFilterType>(
    async ({
      id,
      code,
      title,
      subTitle,
      word,
      image,
      show,
      priority
    }) => {
      await patchPostFilter({
        id,
        code,
        title,
        subTitle,
        word,
        image,
        show,
        priority
      })
    })
}
