import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { TEA } = ROUTER

export const deleteOrigin = async (id: number) => {
  return request<void>({
    url: `${TEA}/origin/${id}`,
    method: Methods.DELETE
  })
}
