import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { FESTIVAL_ADMIN } = ROUTER

export type FestivalScheduleModel = {
  id: number;
  title: string;
  description: string;
  festivalLocation: string;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  image: string | null;
  webUrl: string | null;
}

export type GetFestivalSchedulesResponseType = FestivalScheduleModel[]

export const getFestivalSchedules = () => {
  return request<GetFestivalSchedulesResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `${FESTIVAL_ADMIN}/schedule`,
    method: Methods.GET
  })
}
