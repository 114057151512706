import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type PostCreateShoppingFilterType = {
  code: string;
  title: string;
  subTitle: string;
  word: string;
  image: string;
  show: boolean;
  priority: number;
}

const { SHOPPING_ADMIN } = ROUTER

export const postCreateShoppingFilter = async(data: PostCreateShoppingFilterType) => {
  return request<{ id: number }>({
    url: `${SHOPPING_ADMIN}/filter`,
    method: Methods.POST,
    data
  })
}
