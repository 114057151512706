import { useQuery } from 'react-query'

import { getTeas } from '@/apis/tea/getTeas'

export default function useTeas() {
  return useQuery(
    ['teas'],
    async () => {
      const teas = await getTeas()
      return teas
    }
  )
}
