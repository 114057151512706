import { useQuery } from 'react-query'

import { getFilteredProducts } from '@/apis/shopping/getFilteredProducts'

const getQueryKey = (productId: number) => [`shopping/filtered-product/product/${productId}`]

export default function useFilteredProducts(productId: number){
  return useQuery(
    getQueryKey(productId),
    async () => {
      const filteredProducts = await getFilteredProducts(productId)
      return filteredProducts
    }
  )
}
