import styled from '@emotion/styled'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useNations from '@/hooks/tea/useNations'
import usePostCreateOrigin from '@/hooks/tea/usePostCreateOrigin'

const TeaOriginCreatePage = () => {
  const navigate = useNavigate()
  const { data: nations } = useNations()
  const { mutate: createOrigin } = usePostCreateOrigin()
  const [originData, setOriginData] = useState({
    name: '',
    description: '',
    nationId: 0
  })

  const handleSubmit = async () => {
    if (!originData.name || !originData.description || !originData.nationId) {
      alert('모든 필드를 입력해주세요.')
      return
    }

    createOrigin(originData, {
      onSuccess: () => {
        alert('산지가 생성되었습니다.')
        navigate(-1)
      },
      onError: () => {
        alert('산지 생성에 실패했습니다.')
      }
    })
  }

  return (
    <PageWrapper>
      <h1>산지 추가</h1>
      <FormWrapper>
        <TextField
          label="차산지명"
          value={originData.name}
          onChange={(e) => setOriginData({ ...originData, name: e.target.value })}
        />
        <TextField
          label="설명"
          multiline
          rows={4}
          value={originData.description}
          onChange={(e) => setOriginData({ ...originData, description: e.target.value })}
        />
        <FormControl>
          <InputLabel>생산국</InputLabel>
          <Select
            value={originData.nationId}
            label="생산국"
            onChange={(e) => setOriginData({ ...originData, nationId: Number(e.target.value) })}
          >
            {nations?.map((nation) => (
              <MenuItem
                key={nation.id}
                value={nation.id}>
                {nation.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleSubmit}>
          생성
        </Button>
      </FormWrapper>
    </PageWrapper>
  )
}

export default TeaOriginCreatePage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`
