export const ROUTER = {
  MAIN: '/',
  /* 테스트 버전 */
  TEA: '/admin-tea',
  TEAHOUSE: '/teahouse',
  POST: '/admin-post',
  TEATOGETHER: '/tea-together',
  NOTIFICATION: '/notification',
  TASTINGNOTE: '/tasting-note',
  SHOPPING: '/shopping',
  FESTIVAL: '/festival',
  USER: '/user',
  ACTIVITY_LOG: '/activity-log',
  //Admin
  FESTIVAL_ADMIN: '/festival-admin',
  SHOPPING_ADMIN: '/shopping-admin',
  UPLOAD_ADMIN: '/upload-admin'
}
