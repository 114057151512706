import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { SHOPPING_ADMIN } = ROUTER

export const deleteShoppingFilter = async(id: number) => {
  return request<{ id: number }>({
    url: `${SHOPPING_ADMIN}/filter/${id}`,
    method: Methods.DELETE
  })
}
