import { useMutation } from 'react-query'

import { CreateTeaRequestDto, postCreateTea } from '@/apis/tea/postCreateTea'

export default function usePostCreateTea() {
  return useMutation<{ id: number }, unknown, CreateTeaRequestDto>(
    async (data) => {
      return await postCreateTea(data)
    }
  )
}
