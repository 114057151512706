import { useMutation } from 'react-query'

import { postCreatePostFilter, PostCreatePostFilterType } from '@/apis/post/postCreatePostFilter'

export default function usePostCreatePostFilter() {
  return useMutation<void, unknown, PostCreatePostFilterType>(
    async ({
      code,
      title,
      subTitle,
      word,
      image,
      show,
      priority
    }) => {
      await postCreatePostFilter({ code, title, subTitle, word, image, show, priority })
    })
}
