import DeleteIcon from '@mui/icons-material/Delete'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Button, Card, CardContent, CircularProgress, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import useDeleteFestivalSchedule from '@/hooks/festival/useDeleteFestivalSchedule'
import useFestivalSchedules from '@/hooks/festival/useFestivalSchedules'

const FestivalSchedulePage = () => {
  const navigate = useNavigate()
  const { data: schedules, isLoading } = useFestivalSchedules()
  const { mutate: deleteSchedule } = useDeleteFestivalSchedule()

  const formatDateTime = (dateTime: string) => {
    return dayjs(dateTime).format('YYYY.MM.DD HH:mm')
  }

  const handleDelete = (id: number) => {
    if (window.confirm('정말로 이 일정을 삭제하시겠습니까?')) {
      deleteSchedule(id)
    }
  }

  if (isLoading) {
    return <CircularProgress style={{ marginTop: '20px' }} />
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1>축제 일정 관리</h1>
        <Button
          variant="contained"
          style={{ backgroundColor: '#01c897', color: 'white' }}
          onClick={() => navigate('create')}
        >
          일정 생성
        </Button>
      </div>

      {schedules && schedules.length > 0 ? (
        schedules.map((schedule) => (
          <Card
            key={schedule.id}
            style={{ marginBottom: '15px', padding: '10px' }}
          >
            <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <div style={{ flex: 1 }}>
                {schedule.image && (
                  <img
                    src={schedule.image}
                    alt={schedule.title}
                    style={{ width: '100%', maxWidth: '200px', marginBottom: '10px' }}
                  />
                )}
                <Typography
                  variant="h6"
                  gutterBottom>
                  {schedule.title}
                  {schedule.webUrl && (
                    <IconButton
                      size="small"
                      href={schedule.webUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary">
                  {schedule.description}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginTop: '10px' }}>
                  장소: {schedule.festivalLocation}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ marginTop: '5px', color: '#666' }}>
                  {formatDateTime(schedule.startTime)} ~ {formatDateTime(schedule.endTime)}
                </Typography>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(`revise/${schedule.id}`)}
                >
                  수정
                </Button>
                <IconButton
                  color="error"
                  onClick={() => handleDelete(schedule.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography
          variant="body1"
          color="textSecondary">
          등록된 일정이 없습니다.
        </Typography>
      )}
    </div>
  )
}

export default FestivalSchedulePage
