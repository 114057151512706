import { useQuery } from 'react-query'

import { getShoppingFilters } from '@/apis/shopping/getShoppingFilters'

const getQueryKey = () => ['shopping/filter']

export default function useShoppingFilters(){
  return useQuery(
    getQueryKey(),
    async () => {
      const filters = await getShoppingFilters()
      return filters
    }
  )
}
