import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type Nation = {
  id: number;
  name: string;
  code: string;
}

const { TEA } = ROUTER

export const getNations = () => {
  return request<Nation[]>({
    url: `${TEA}/nation`,
    method: Methods.GET
  })
}
