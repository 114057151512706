import { useMutation } from 'react-query'

import { patchUpdateNation, UpdateNationRequestDto } from '@/apis/tea/patchUpdateNation'

type UpdateNationParams = {
  id: number;
  data: UpdateNationRequestDto;
}

export default function usePatchUpdateNation() {
  return useMutation<void, unknown, UpdateNationParams>(
    async ({ id, data }) => {
      await patchUpdateNation(id, data)
    }
  )
}
