import { useMutation } from 'react-query'

import { deleteShoppingFilter } from '@/apis/shopping/deleteShoppingFilter'

export default function useDeleteShoppingFilter() {
  return useMutation<void, unknown, { id: number }>(
    async ({ id }) => {
      await deleteShoppingFilter(id)
    })
}
