import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import useFestivalById from '@/hooks/festival/useFestivalById'
import usePostCreateParticipator from '@/hooks/festival/usePostCreateParticipator'
import { isError } from '@/libs/request'

const FestivalParticipatorCreatePage = () => {
  const { id } = useParams()
  const { data: festival } = useFestivalById(Number(id))
  const [companyName, setCompanyName] = useState('')
  const [boothNumber, setBoothNumber] = useState<number>(1)
  const [boothDescription, setBoothDescription] = useState('')
  const [tea, setTea] = useState('')
  const [country, setCountry] = useState<'KR' | 'CN' | 'JP' | 'LK' | 'TW' | 'US'>('KR')
  // 위치
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)

  const handleBoothNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/^\d*$/.test(e.target.value)) {
      setBoothNumber(Number(e.target.value))
    }
  }

  const { mutate: postParticipator } = usePostCreateParticipator()

  const clickHandler = () => {
    postParticipator(
      { festivalId: 1, name: companyName, boothNumber, description: boothDescription, handledTea: tea, country, tag: 'GYEONGJU_2023', latitude: `${latitude}`, longitude: `${longitude}` },
      {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            alert(message)
          }
        },
        onSuccess: (data) => {
          const messageString = `Created : #${data.boothNumber} ${data.name} (${data.participatorId})`
          alert(`${messageString}`)
        }
      }
    )
  }

  // Naver Map
  const mapElement = useRef<HTMLDivElement>(null)
  const markerRef = useRef<naver.maps.Marker | null>(null)

  useEffect(() => {
    if (!mapElement.current) return

    const { naver } = window

    if (!naver) {
      console.error('Naver Maps API is not loaded')
      return
    }

    const mapOptions: naver.maps.MapOptions = {
      center: new naver.maps.LatLng(Number(festival?.latitude), Number(festival?.longitude)), // 중심좌표 설정
      zoom: 10
    }

    const map = new naver.maps.Map(mapElement.current, mapOptions)

    new naver.maps.Marker({
      position: new naver.maps.LatLng(Number(festival?.latitude), Number(festival?.longitude)),
      title: '중심위치',
      map: map,
      icon: {
        url: 'https://www.iconpacks.net/icons/2/free-location-pin-icon-2965-thumb.png',
        scaledSize: new naver.maps.Size(20, 20), // Custom size
        origin: new naver.maps.Point(0, 0),
        anchor: new naver.maps.Point(10, 20)
      }
    })
    festival?.participators.map((participator) => {
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(Number(participator?.latitude), Number(participator?.longitude)),
        title: `${participator.boothNumber}`,
        map: map,
        icon: {
          url: 'https://images.ctfassets.net/3prze68gbwl1/assetglossary-17su9wok1ui0z7w/c4c4bdcdf0d0f86447d3efc450d1d081/map-marker.png', // URL to your custom marker image
          scaledSize: new naver.maps.Size(20, 20), // Custom size
          origin: new naver.maps.Point(0, 0),
          anchor: new naver.maps.Point(10, 20) // Anchor the bottom center of the marker
        }
      })
      const infoWindow = new naver.maps.InfoWindow({
        content: `<div style="padding:5px; font-size:14px; font-weight:bold;">Booth #${participator.boothNumber}</div>`,
        borderColor: '#3f51b5', // Optional: Customize the border color
        borderWidth: 2 // Optional: Border width
      })

      // Open the InfoWindow immediately (can also be shown on click if preferred)
      naver.maps.Event.addListener(marker, 'click', function () {
        infoWindow.open(map, marker)
      })
    })

    // 클릭 이벤트 추가
    naver.maps.Event.addListener(map, 'click', function (e) {
      const clickedLat = e.coord.lat()
      const clickedLng = e.coord.lng()

      // 클릭된 좌표를 state로 설정
      setLatitude(clickedLat)
      setLongitude(clickedLng)

      // 마커 이동
      if (markerRef.current) {
        markerRef.current.setMap(null)
      }

      markerRef.current = new naver.maps.Marker({
        position: new naver.maps.LatLng(clickedLat, clickedLng),
        map: map
      })
    })
  }, [festival])

  return (
    <PageWrapperCentered>
      <FormWrapper>
        <Title>참여 단체 만들기</Title>
        <div
          ref={mapElement}
          style={{ width: '100%', height: '400px' }} />
        <div>
          현재위치: {latitude} : {longitude}
        </div>
        <FormGroup>
          <Label>업체 이름</Label>
          <Input
            placeholder='업체 이름 입력'
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)} />
        </FormGroup>

        <FormGroup>
          <Label>부스 번호</Label>
          <Input
            type='number'
            placeholder='부스 번호 입력'
            value={boothNumber.toString()}
            onChange={handleBoothNumberChange} />
        </FormGroup>

        <FormGroup>
          <Label>부스 설명</Label>
          <TextArea
            placeholder='부스 설명 입력'
            value={boothDescription}
            onChange={(e) => setBoothDescription(e.target.value)} />
        </FormGroup>

        <FormGroup>
          <Label>준비된 차</Label>
          <Input
            placeholder='차(Tea) 입력'
            value={tea}
            onChange={(e) => setTea(e.target.value)} />
        </FormGroup>

        <FormGroup>
          <Label>국가 선택</Label>
          <CountryButtonWrapper>
            <CountryButton
              isActive={country === 'KR'}
              onClick={() => setCountry('KR')}>🇰🇷 KR</CountryButton>
            <CountryButton
              isActive={country === 'CN'}
              onClick={() => setCountry('CN')}>🇨🇳 CN</CountryButton>
            <CountryButton
              isActive={country === 'JP'}
              onClick={() => setCountry('JP')}>🇯🇵 JP</CountryButton>
            <CountryButton
              isActive={country === 'LK'}
              onClick={() => setCountry('LK')}>🇱🇰 LK</CountryButton>
            <CountryButton
              isActive={country === 'TW'}
              onClick={() => setCountry('TW')}>🇹🇼 TW</CountryButton>
            <CountryButton
              isActive={country === 'US'}
              onClick={() => setCountry('US')}>🇺🇸 US</CountryButton>
          </CountryButtonWrapper>
        </FormGroup>

        <Button onClick={clickHandler}>참여 단체 만들기</Button>
      </FormWrapper>
    </PageWrapperCentered>
  )
}

export default FestivalParticipatorCreatePage

// Styled components
const PageWrapperCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
`

const FormWrapper = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

const FormGroup = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 100px;
  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`

const CountryButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const CountryButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#3f51b5' : '#f4f4f9')};
  color: ${({ isActive }) => (isActive ? 'white' : 'black')};
  border: 1px solid #ddd;
  border-radius: 5px;
  &:hover {
    background-color: #3f51b5;
    color: white;
  }
`

const Button = styled.button`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #2c387e;
  }
`
