import { useMutation } from 'react-query'

import { deleteFilteredProduct } from '@/apis/shopping/deleteFilteredProduct'

export default function useDeleteFilteredProduct() {
  return useMutation<void, unknown, { id: number }>(
    async ({ id }) => {
      await deleteFilteredProduct(id)
    })
}
