import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { Nation } from './getNations'

const { TEA } = ROUTER

export const getNation = (id: number) => {
  return request<Nation>({
    url: `${TEA}/nation/${id}`,
    method: Methods.GET
  })
}
