import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type UpdateTeaRequestDto = {
  name?: string;
  description?: string;
  imageIds?: number[];
  originId?: number | null;
  brandId?: number | null;
  parentTeaIds?: number[];
  childTeaIds?: number[];
  priority?: number;
}

const { TEA } = ROUTER

export const patchUpdateTea = async (id: number, data: UpdateTeaRequestDto) => {
  return request<void>({
    url: `${TEA}/tea/${id}`,
    method: Methods.PATCH,
    data
  })
}
