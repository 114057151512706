import styled from '@emotion/styled'
import { Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useNation from '@/hooks/tea/useNation'
import usePatchUpdateNation from '@/hooks/tea/usePatchUpdateNation'

const TeaNationUpdatePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: nation } = useNation(Number(id))
  const { mutate: updateNation } = usePatchUpdateNation()
  const [nationData, setNationData] = useState({
    name: '',
    code: ''
  })

  useEffect(() => {
    if (nation) {
      setNationData({
        name: nation.name,
        code: nation.code
      })
    }
  }, [nation])

  const handleSubmit = () => {
    if (!nationData.name || !nationData.code) {
      alert('모든 필드를 입력해주세요.')
      return
    }

    updateNation({
      id: Number(id),
      data: nationData
    }, {
      onSuccess: () => {
        alert('생산국이 수정되었습니다.')
        navigate(-1)
      },
      onError: () => {
        alert('생산국 수정에 실패했습니다.')
      }
    })
  }

  return (
    <PageWrapper>
      <h1>생산국 수정</h1>
      <FormWrapper>
        <TextField
          label="국가명"
          value={nationData.name}
          onChange={(e) => setNationData({ ...nationData, name: e.target.value })}
        />
        <TextField
          label="국가 코드"
          value={nationData.code}
          onChange={(e) => setNationData({ ...nationData, code: e.target.value })}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}>
          수정
        </Button>
      </FormWrapper>
    </PageWrapper>
  )
}

export default TeaNationUpdatePage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`
