import { supabaseClient } from '@/libs/supabase'

export interface UserActivityLog {
  id: number;
  created_at: string;
  method: string;
  action: string;
  ip_address: string;
  fcm_token: string;
  device_info: string;
  user_id: string;
}

export interface DailyStats {
  date: string;
  unique_users: number;
  total_activities: number;
}

export const getUserActivityLogs = async (): Promise<UserActivityLog[]> => {
  const { data, error } = await supabaseClient
    .from('user_activity_logs')
    .select('*')
    .order('created_at', { ascending: false })

  if (error) {
    throw error
  }

  return data || []
}

export const getDailyStats = async (days = 7): Promise<DailyStats[]> => {
  const { data, error } = await supabaseClient
    .from('user_activity_logs')
    .select('created_at, ip_address, user_id')
    .gte('created_at', new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString())
    .order('created_at', { ascending: false })

  if (error) {
    throw error
  }

  const dailyStats = new Map<string, { unique_users: Set<string>; total_activities: number }>()

  data?.forEach((log) => {
    const date = new Date(log.created_at).toISOString().split('T')[0]
    if (!dailyStats.has(date)) {
      dailyStats.set(date, { unique_users: new Set(), total_activities: 0 })
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const stats = dailyStats.get(date)!
    stats.unique_users.add(`${log.ip_address}-${log.user_id}`)
    stats.total_activities++
  })

  return Array.from(dailyStats.entries()).map(([date, stats]) => ({
    date,
    unique_users: stats.unique_users.size,
    total_activities: stats.total_activities
  }))
}
