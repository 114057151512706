import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type UpdateNationRequestDto = {
  name: string;
  code: string;
}

const { TEA } = ROUTER

export const patchUpdateNation = async (id: number, data: UpdateNationRequestDto) => {
  return request<void>({
    url: `${TEA}/nation/${id}`,
    method: Methods.PATCH,
    data
  })
}
