import { useMutation } from 'react-query'

import { patchShoppingFilter, PatchShoppingFilterType } from '@/apis/shopping/patchShoppingFilter'

export default function usePatchShoppingFilter() {
  return useMutation<void, unknown, PatchShoppingFilterType>(
    async ({
      id,
      code,
      title,
      subTitle,
      word,
      image,
      show,
      priority
    }) => {
      await patchShoppingFilter({
        id,
        code,
        title,
        subTitle,
        word,
        image,
        show,
        priority
      })
    })
}
