import { useQuery } from 'react-query'

import { getTea } from '@/apis/tea/getTea'

export default function useTea(id: number) {
  return useQuery(
    ['tea', id],
    async () => {
      const tea = await getTea(id)
      return tea
    },
    { enabled: !!id }
  )
}
