import styled from '@emotion/styled'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useNations from '@/hooks/tea/useNations'
import useOrigin from '@/hooks/tea/useOrigin'
import usePatchUpdateOrigin from '@/hooks/tea/usePatchUpdateOrigin'

const TeaOriginUpdatePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: origin } = useOrigin(Number(id))
  const { data: nations } = useNations()
  const { mutate: updateOrigin } = usePatchUpdateOrigin()
  const [originData, setOriginData] = useState({
    name: '',
    description: '',
    nationId: 0
  })

  useEffect(() => {
    if (origin) {
      setOriginData({
        name: origin.name,
        description: origin.description,
        nationId: origin.nationId
      })
    }
  }, [origin])

  const handleSubmit = () => {
    if (!originData.name || !originData.description || !originData.nationId) {
      alert('모든 필드를 입력해주세요.')
      return
    }

    updateOrigin({
      id: Number(id),
      data: originData
    }, {
      onSuccess: () => {
        alert('산지가 수정되었습니다.')
        navigate(-1)
      },
      onError: () => {
        alert('산지 수정에 실패했습니다.')
      }
    })
  }

  return (
    <PageWrapper>
      <h1>산지 수정</h1>
      <FormWrapper>
        <TextField
          label="산지명"
          value={originData.name}
          onChange={(e) => setOriginData({ ...originData, name: e.target.value })}
        />
        <TextField
          label="설명"
          multiline
          rows={4}
          value={originData.description}
          onChange={(e) => setOriginData({ ...originData, description: e.target.value })}
        />
        <FormControl>
          <InputLabel>생산국</InputLabel>
          <Select
            value={originData.nationId}
            label="생산국"
            onChange={(e) => setOriginData({ ...originData, nationId: Number(e.target.value) })}
          >
            {nations?.map((nation) => (
              <MenuItem
                key={nation.id}
                value={nation.id}>
                {nation.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleSubmit}>
          수정
        </Button>
      </FormWrapper>
    </PageWrapper>
  )
}

export default TeaOriginUpdatePage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`
