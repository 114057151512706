import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

export type UpdateTeaOriginRequestDto = {
  name: string;
  description: string;
  nationId: number;
}

const { TEA } = ROUTER

export const patchUpdateOrigin = async (id: number, data: UpdateTeaOriginRequestDto) => {
  return request<void>({
    url: `${TEA}/origin/${id}`,
    method: Methods.PATCH,
    data
  })
}
