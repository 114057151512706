import { useQuery } from 'react-query'

import { getFestivalScheduleById } from '@/apis/festival/getFestivalScheduleById'

const getQueryKey = (id: number) => [`festival-schedule/${id}`]

export default function useFestivalScheduleById(id: number) {
  return useQuery(
    getQueryKey(id),
    async () => {
      const schedule = await getFestivalScheduleById(id)
      return schedule
    }
  )
}
