import styled from '@emotion/styled'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getBrands } from '@/apis/shopping/getBrands'
import useOrigins from '@/hooks/tea/useOrigins'
import usePatchUpdateTea from '@/hooks/tea/usePatchUpdateTea'
import useTea from '@/hooks/tea/useTea'
import useTeas from '@/hooks/tea/useTeas'
import usePostUploadFiles from '@/hooks/upload/useUploadFiles'

const TeaUpdatePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: tea } = useTea(Number(id))
  const { data: origins } = useOrigins()
  const { data: brands } = useQuery('brands', getBrands)
  const { data: teas } = useTeas()
  const { mutateAsync: updateTea } = usePatchUpdateTea()
  const { mutateAsync: uploadFiles } = usePostUploadFiles()

  const [teaData, setTeaData] = useState({
    name: '',
    description: '',
    originId: null as number | null,
    brandId: null as number | null,
    imageIds: [] as number[],
    parentTeaIds: [] as number[],
    childTeaIds: [] as number[],
    priority: 1
  })
  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    if (tea) {
      setTeaData({
        name: tea.name,
        description: tea.description,
        originId: tea.origin?.id || null,
        brandId: tea.brand?.id || null,
        imageIds: tea.imageAndTeas.map((iat: any) => iat.image.id),
        parentTeaIds: tea.parentTeas.map((pt) => pt.id),
        childTeaIds: tea.childTeas.map((ct) => ct.id),
        priority: tea.priority
      })
    }
  }, [tea])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files))
    }
  }

  const handleSubmit = async () => {
    if (!teaData.name || !teaData.description) {
      alert('필수 필드를 입력해주세요.')
      return
    }

    try {
      if (files.length > 0) {
        const uploadResult = await uploadFiles(files)
        teaData.imageIds = uploadResult.images.map((image) => image.id)
      }

      await updateTea({
        id: Number(id),
        data: teaData
      }, {
        onSuccess: () => {
          alert('차가 수정되었습니다.')
          navigate(-1)
        }
      })
    } catch (error) {
      alert('차 수정에 실패했습니다.')
    }
  }

  return (
    <PageWrapper>
      <h1>차 수정</h1>
      <FormWrapper>
        <TextField
          label="차 이름"
          value={teaData.name}
          onChange={(e) => setTeaData({ ...teaData, name: e.target.value })}
        />
        <TextField
          label="설명"
          multiline
          rows={4}
          value={teaData.description}
          onChange={(e) => setTeaData({ ...teaData, description: e.target.value })}
        />
        <FormControl>
          <InputLabel>원산지</InputLabel>
          <Select
            value={teaData.originId ?? ''}
            label="원산지"
            onChange={(e) => setTeaData({ ...teaData, originId: e.target.value ? Number(e.target.value) : null })}
          >
            <MenuItem value="">
              <em>선택 안함</em>
            </MenuItem>
            {origins?.map((origin) => (
              <MenuItem
                key={origin.id}
                value={origin.id}>
                {origin.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>브랜드</InputLabel>
          <Select
            value={teaData.brandId ?? ''}
            label="브랜드"
            onChange={(e) => setTeaData({ ...teaData, brandId: e.target.value ? Number(e.target.value) : null })}
          >
            <MenuItem value="">
              <em>선택 안함</em>
            </MenuItem>
            {brands?.map((brand) => (
              <MenuItem
                key={brand.id}
                value={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>부모 차</InputLabel>
          <Select
            multiple
            value={teaData.parentTeaIds}
            label="부모 차"
            onChange={(e) => {
              const value = e.target.value as number[]
              setTeaData({ ...teaData, parentTeaIds: value })
            }}
          >
            {teas?.filter((t) => t.id !== Number(id)).map((tea) => (
              <MenuItem
                key={tea.id}
                value={tea.id}>
                {tea.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>자식 차</InputLabel>
          <Select
            multiple
            value={teaData.childTeaIds}
            label="자식 차"
            onChange={(e) => {
              const value = e.target.value as number[]
              setTeaData({ ...teaData, childTeaIds: value })
            }}
          >
            {teas?.filter((t) => t.id !== Number(id)).map((tea) => (
              <MenuItem
                key={tea.id}
                value={tea.id}>
                {tea.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          type="number"
          label="우선순위"
          value={teaData.priority}
          onChange={(e) => setTeaData({ ...teaData, priority: Number(e.target.value) })}
        />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          accept="image/*"
        />
        <Button
          variant="contained"
          onClick={handleSubmit}>
          수정
        </Button>
      </FormWrapper>
    </PageWrapper>
  )
}

export default TeaUpdatePage

const PageWrapper = styled.div`
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`
